const dishes = [
  'Meatloaf',
  'Spaghetti and Meatballs',
  'Chicken Pot Pie',
  'Fajitas',
  'Chili',
  'Lasagna',
  'Tortellini',
  'Stuffed Peppers',
  'Chicken Parmesan',
  'Jambalaya',
  'Pot Roast',
  'Enchiladas',
  'Potato and Leek Soup',
  'Roast Chicken',
  'Beef Stew',
  'Pork Chops',
  'Chicken and Dumplings',
  'Chicken over Rice',
  'Seafood Chowder',
  'Tilapia',
  'Grilled Salmon',
  'Macaroni and Cheese',
  'Shepards Pie',
  'Burger',
  'Lamb Sirloin',
  'Ramen',
  'Pho',
  'Perogis',
  'Dumplings',
  'Doner',
  'Chicken Wings',
  'Mushroom Rissotto',
  'Ceasar Salad',
  'Taco Salad',
  'Beef and Mushrooms',
  'Chicken and Broccoli Casserole',
  'Celery Soup',
  'Chicken Paprikash',
  'Quesadillas',
  'Chicken and Gravy',
  'Shrimp Kabobs',
  'Ribs',
  'Pizza',
  'Tuna Casserole',
  'Bratwurst',
  'Sloppy Joes',
  'Salisbury Steaks',
  'Country Fried Chicken',
  'Rice and White Bean Soup',
  'Split Pea Soup',
  'Stir Fry',
  'Posole',
  'Coq au Vin',
  'Spicy Red Curry',
  'Teriyaki Salmon & Quinoa',
  'Italian Sausage & Peppers',
  'Polenta',
  'Shrimp & Grits',
  'Lamb Chops',
  'Thai Lettuce Wraps',
  'Beef & Broccoli',
  'Steak & Mashed Potatoes',
  'Skirt Steak',
  'Steak Frites',
  'Beef Stroganoff',
  'French Onion Soup',
  'Gumbo',
  'Baked Ziti',
  'Fried Green Tomato Sandwich',
  'Brisket',
  'Chicken Fried Steak',
  'Chicken Tetrazzini',
  'Baked Potato Soup',
  'Squash Casserole',
  'Fried Catfish',
  'Fish & Chips',
  'Tuna Melt',
  'BLT',
  'Patty Melt',
  'Fried Chicken',
  'Lumpia',
  'Turkish Pizza',
  'Chickpea Salad',
  'Tandoori Chicken',
  'Prawn Masala',
  'Butter Chicken',
  'Chicken Vindaloo',
  'Curried Rice & Lentils',
  'Calzone',
  'Chicken Adobo',
  'Arroz con Pollo',
  'Paella',
  'Burrito',
  'Beef Bulgogi',
  'Thai Basil Chicken',
  'Jerk Chicken',
  'Goulash',
  'Fried Rice',
  'Pork Orzo & Feta',
  'Seared Scallops',
  'Sushi',
  'Burgers',
  'Thai Curry',
  'Tacos',
  'Pancakes',
  'Tequila',
  'Worth Wild',
]

export default dishes
